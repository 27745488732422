import * as React from 'react'
import { container } from './layout.module.css'

const Layout = ({ pageTitle, children }) => {
  return (
    <main className={container}>
      <title>{pageTitle}</title>
           {children}
    </main>
  )
}
export default Layout