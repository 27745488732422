import * as React from 'react'
import { boxWrapper, textWrapper, imgWrapper2, boxWrapper2, description } from './single-box.module.css'



const SingleBox2 = ({ header, description, image, alt }) => {
    return (
     <div className={[boxWrapper, boxWrapper2].join(' ')}>
          <div className={imgWrapper2}>
             <img src={image} alt={alt}></img>
        </div>
         <div className={textWrapper}>
            <h3 >{header}</h3>
            <div className={description} >{description}</div>
        </div>
        
     </div>
    )
  }
  export default SingleBox2