import * as React from 'react'
import { ti, decorator } from './header.module.css'



const Header = ({title}) => {
  return (
    <div>
   <h2 className={ti}>{title}</h2>
<div className={decorator}></div>
    </div>
  )
}
export default Header