import * as React from 'react'
 import { infoBoxWrapper, openingHoursBox, contactBox, addressBox, decorator } from './info-box.module.css'
 import { container } from './layout.module.css'

 import clock from "../svg/clock-regular.svg"
 import phone from "../svg/phone-solid.svg"
 import marker from "../svg/map-marker-alt-solid.svg"





const InfoBox = () => {
  return (
   <div className={infoBoxWrapper}>
       <div className={openingHoursBox}>
           <img src={clock} alt="Ikona zegara"></img>
           <p>Godziny otwarcia:</p>
           <p>poniedziałek - środa: 7.00 - 17.00</p>
           <div className={decorator}></div>
           <p>wtorek, czwartek, piątek: 10.00 - 17.00</p>
           <div className={decorator}></div>
           <p>sobota - w każdą sobotę: 10.00 - 14.00</p>
       </div>
       <div className={contactBox}>
            <img src={phone} alt="Ikona telefonu"></img>
           <p>Kontakt do nas</p>
           <a href="tel:+48 604 165 756">+48 604 165 756</a>
           <p>Prosimy o kontakt telefoniczny, w większość dni pracujemy dłużej- w godzinach działania przychodni OrtoGo.</p>
       </div>
       <div className={addressBox}>
            <img src={marker} alt="Ikona znacznika na mapie"></img>
           <p>Gdzie jesteśmy?</p>
           <p>ul. Medyczna 8 lok 138 na parterze <br></br>
             (wejście od ulicy Honorowych Dawców Krwi)<br></br>
             Płock 09-400
             </p>
       </div>
   </div>
  )
}
export default InfoBox