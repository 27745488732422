import * as React from 'react'
 import { contactSection, textWrapper } from './contact.module.css'
 import { container } from './layout.module.css'



const Contact = () => {
  return (
   <section className={contactSection}>
     <div className={container}>
     <div className={textWrapper}>
       <p>Jesteśmy tu dla Ciebie!</p>
       <p>Aby uzyskać fachową pomoc w doborze sprzętu lub dowiedzieć się więcej o konkretnych modelach zadzwoń pod numer:</p>
       <a href="tel:+48604165756">604-165-756</a>
       <p>Zapraszamy do kontaktu!</p>
     </div>
     </div>
     </section>
  )
}
export default Contact