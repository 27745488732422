import * as React from 'react'
import SingleBox from './single-box'
import SingleBox2 from './single-box2'
import Header from "../components/header"


import zylaki from "../images/zylaki.jpg"
import stopy from "../images/zdrowie_stop.jpg"
import sen from "../images/sen.jpg"
import lozko from "../images/lozko_reh.png"
import pilka from "../images/pilka.jpg"


const Products = () => {
  return (
      <section>
              <Header title="Nasze produkty"></Header>

    <SingleBox
    header="ZDROWE NOGI"
    description={<div><b>Żylaki</b> są obecnie bardzo powszechnym problemem, dotykającym zarówno kobiet, 
        jak i mężczyzn. Przyczyny ich powstawania u każdego z nas mogą być zupełnie różne, natomiast najczęściej 
        za żylaki odpowiada: siedzący tryb życia, praca stojąca, która jest dla nóg bardzo dużym obciążeniem, praca 
        biurowa, nadwaga i otyłość i brak ruchu. Choroby żylne często są również spowodowane uwarunkowaniami genetycznymi. 
        Dla osób, które chcą im zapobiegać oraz dla pacjentów, którzy profilaktycznie powinni poddać się 
        kompresjoterapii po zabiegach, w naszych asortymencie znajdą Państwo różnego rodzaju produkty uciskowe, 
        które dzięki swojej konstrukcji o dprowadzają nadmiar krwi z dolnych partii ciała i sprzyjają sprawnemu krążeniu. 
        W naszym sklepie ortopedycznym znajdą Państwo <b>podkolanówki kompresyjne, żele chłodzące, profilaktyczne rajstopy przeciwżylakowe, 
        pończochy samonośne kompresyjne w różnych kolorach oraz odzież uciskową</b></div>}
        image={zylaki}
        alt="Żylaki"
    />
        <SingleBox2
    header="ZDROWIE STÓP"
    description={<div>Zadaniem naszych stóp jest prawidłowe utrzymanie ciężaru całego naszego 
    ciała. Są także głównym narządem, który pozwala nam na sprawne poruszanie. Dlatego tak 
    ważne jest zwracanie szczególnej uwagi na ich stan i unikanie wszelkich deformacji,
     mogących być następstwem zbyt dużego przeciążania naszych stóp. W asortymencie naszego 
     sklepu znajdą Państwo artykuły, które pozwalają zadbać o stopy, jak i produkty 
     korekcyjne, które umożliwiają powrót do ich dobrej kondycji, takie jak: <b>półwkładki 
     piankowe, plastry na odciski, kliny międzypalcowe, podpiętki, osłony na haluksy, 
     wkładki ortopedyczne, oddychające wkładki, wkładki korygujące, elastyczne osłony, 
     opaski żelowe, preparaty antybakteryjne, skarpetki medyczne i bezuciskowe, separatory, 
     wkładki dziecięce, produkty pielęgnacyjne i buty ortopedyczne.</b></div>}
     image={stopy}
     alt="Stopa"

    />
    <SingleBox
    header="ZDROWY SEN"
    description={<div>Głęboki, spokojny i długi sen to główna podstawa naszego funkcjonowania. 
    Praca, opieka nad domem, codziennie aktywności, czy treningi sprawiają, że nasz organizm
    otrzebuje zdrowej i komfortowej regeneracji, by mu sprawnie działać. Podczas snu nasz 
    organizm nie tylko regeneruje się i wypoczywa, ale ma też czas na redukcję
     nagrodzonych w ciągu dnia napięć i stresów. Aby umożliwić naszym klientom maksymalnie
      komfortowy i wygodny wypoczynek w asortymencie naszego sklepu umieściliśmy produkty
       takie jak: <b>zagłówki, podpórki pod plecy, poduszki podróżne, różne rodzaje poduszek
        ortopedycznych,</b> które nie tylko pozwalają na spokojny sen, ale też dbają o nasz
         kręgosłup,<b> półwałki ortopedyczne, profilowane poduszki dla najmłodszych, a 
         także koce i poduszki elektryczne.</b></div>}
         image={sen}
         alt="Śpiąca kobieta"
    />
    
        <SingleBox2
    header="ĆWICZENIA/REHABILITACJA"
    description={<div>Dostępne w naszym sklepie sprzęty do ćwiczeń rehabilitacyjnych pozwolą
     na osiąganie doskonałych rezultatów na drodze poprawiania lub powrotu do pełnej 
     sprawności fizycznej. Wśród dostępnego asortymentu znajdą Państwo akcesoria takie 
     jak: <b>piłki do masażu, taśm rehabilitacyje, kompresy i okłady 
     ochładzające/rozgrzewające, sprzęty do rehabilitacji mięśni dłoni, gumy 
     do ćwiczeń, klasyczne przyrządy do masażu, stabilizatory do ćwiczeń, rollery 
     z wypustkami, piłki rehabilitacyjne i akcesoria ortopedyczne. </b></div>}
     image={pilka}
     alt="Kobieta ćwicząca na piłce rehabilitacyjnej"
    />

        <SingleBox
    header="WYNAJEM ŁÓŻEK ORTOPEDYCZNYCH"
    description={<div>Jeśli potrzebujesz łóżka do pielęgnacji osób chorych, z
     przejściowymi problemami motorycznymi lub po wypadkach - doskonałym 
     rozwiązaniem będzie <b>wynajem łóżka ortopedycznego</b>, które umożliwi czynności 
     pielęgnacyjne, zmianę pozycji czy ułożenie osoby w łóżku. W ofercie <b>dostawa i
     montaż u klienta </b>, atrakcyjne ceny.
<br></br><br></br>
    <b>Skontaktuj się z nami</b> - doradzimy odpowiednie rozwiązanie do potrzeb osoby leżącej.</div>}
    image={lozko}
    alt="Łózko ortopedyczne i mężczyzna na wózku inwalidzkim"
    />

    </section>
  )
}
export default Products