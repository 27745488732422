import * as React from "react"
import Layout from '../components/layout'
import Products from '../components/products'
import Reviews from "../components/reviews"
import About from "../components/about"
import Strengths from "../components/strengths"
import Contact from "../components/contact"
import MainSection from "../components/main-section"
import {Helmet} from "react-helmet";
import favicon from "../images/favicon-fizjozakupy.png"

const IndexPage = () => {
    return (
        <div>
            <div className="application">
                <Helmet
                    htmlAttributes={{
                        lang: 'pl'
                    }}
                >
                    <meta charSet="utf-8"/>
                    <title>Fizjo Zakupy - Sklep Medyczny Płock</title>
                    <meta name="description" content='Sklep medyczny z zaopatrzeniem ortopedycznym w Płocku. Zapraszamy!' />
                    <link rel="icon" type="image/png" href={ favicon }/>
                </Helmet>
                <MainSection/>
                <Layout pageTitle="Fizjo Zakupy">
                    <About/>
                    <Strengths/>
                    <Reviews/>
                    <Products/>
                </Layout>
                <Contact/>
            </div>
        </div>
    )
}

export default IndexPage
