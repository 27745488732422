import * as React from 'react'
import {useState} from "react";
import {formWrapper, logoWrapper, textWrapper, arrowWrapper, inputWrapper, checkboxWrapper} from './form.module.css'
import logo from "../images/LOGO_FIZJO.png"
import arrow from "../svg/angle-down-solid.svg"

const Form = () => {
    const [userData, setUserData] = useState({});
    const [feedback, setFeedback] = useState({ type: '', message: '' });
    const axios = require('axios');
    const FormData = require('form-data');

    const updateValues = ({ target: { name, value }}) => {
        if( name == 'gdpr' ){
            if(userData['gdpr'] == 'accept'){
                setUserData({ ...userData, gdpr : false });
            } else {
                setUserData( { ...userData, [name]: value});
            }
        } else {
            setUserData( { ...userData, [name]: value});
        }
        //console.log(userData);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if( userData['gdpr'] != 'accept' ){
            setFeedback({ type: 'error', message: 'Zaakceptuj politykę prywatności aby wysłać wiadomość.'});
        } else {
            let data = new FormData();
            setFeedback({ type: 'info', message: 'Wysyłanie wiadomości'});
            data.append('your-name', userData['your-name']);
            data.append('your-tel', userData['your-tel']);
            data.append('your-email', userData['your-email']);
            data.append('your-message', 'Wybrany termin: ' + String(userData['your-message']));

            const config = {
                method: 'post',
                url: 'https://wp.ortogo.pl/wp-json/contact-form-7/v1/contact-forms/306/feedback',
                data : data
            };

            axios(config)
                .then(function (response) {
                    if(response.data.status === "mail_sent"){
                        console.log(data);
                        console.log(JSON.stringify(response.data));
                        setFeedback({ type: 'success', message: 'Dziękujemy, wiadomość została wysłana.'});
                    } else {
                        setFeedback({ type: 'error', message: 'Formularz zawiera błędy. Sprawdź poprawność danych.'});
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    return (
        <div className={formWrapper}>
            <div className={logoWrapper}>
                <h1>Sklep <br/> Medyczny</h1>
                <img src={logo} alt="Logo sklepu Fizjo Zakupy" />
            </div>
            <div className={textWrapper}>
                <h2>z zaopatrzeniem ortopedycznym</h2>
                <p>Służymy radą i pomocą, zarówno na miejscu, jak i telefonicznie.
                    <br/>
                    Zapraszamy!
                </p>
                <p>Wybierz datę, a my do Ciebie oddzwonimy</p>
                <div className={arrowWrapper}>
                    <img src={arrow} alt="Ikona strzałki" />
                </div>
            </div>

            <form>
                <div className={inputWrapper}>
                    <input name="your-name" placeholder="Twoje imię" type="text" onChange={updateValues} />
                </div>
                <div className={inputWrapper}>
                    <input name="your-tel" placeholder="Twój numer telefonu" type="number" onChange={updateValues} />
                </div>
                <div className={inputWrapper}>
                    <input name="your-email" placeholder="Twój email" type="email" onChange={updateValues} />
                </div>
                <div className={inputWrapper}>
                    <input name="your-message" placeholder="Data" type="datetime-local" onChange={updateValues} />
                </div>
                <div className={checkboxWrapper}>
                    <label>Wyrażam zgodę na otrzymywanie informacji handlowych od Sklep Ortopedyczny Wiesława Kopeć
                        [*czytaj więcej]<span>Zgoda na korzystanie z plików cookie zgodnie z warunkami polityki prywatności podczas pierwszej wizyty na naszej stronie pozwala nam na korzystanie z plików cookie przy każdej kolejnej wizycie. Administratorem Twoich danych jest Sklep Ortopedyczny Wiesława Kopeć z siedzibą w ul. Medyczna 8 lok 138 parter, 09-400 Płock wpisany do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, pod numerem NIP: 9710290147. W sprawach związanych z przetwarzaniem i ochroną danych osobowych wyznaczony został punkt kontaktowy w postaci adresu e-mail: skleportopedycznyplock@gmail.com z dopiskiem: „Ochrona Danych Osobowych”.</span></label>
                    <input name="gdpr"  type="checkbox" value={'accept'} onClick={updateValues} />
                </div>
                <div className={inputWrapper}>
                    <input type="submit" value="WYŚLIJ" onClick={handleSubmit}/>
                </div>
                {
                    feedback.type !== '' ?
                        <div className={inputWrapper}>
                            <p>{feedback.message}</p>
                        </div>
                        : null
                }
            </form>
        </div>
    )
}
export default Form