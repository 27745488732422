import * as React from 'react'
import SingleBox from './single-box'
import SingleBox2 from './single-box2'
import Header from "../components/header"

import { aboutSection } from './about.module.css'

import grafika1 from "../images/grafika_1.jpg"
import grafika2 from "../images/grafika_2.jpg"
import grafika3 from "../images/gr_7.jpg"
import grafika4 from "../images/grafika_4.jpg"



const About = () => {
  return (
   <section className={aboutSection}>
             <Header title="O nas"></Header>

       <SingleBox
    description={<div>Nasz Sklep Ortopedyczny powstał w odpowiedzi na zapotrzebowanie pacjentów na sprzęty medyczne. 
    Wśród naszego asortymentu znajdą Państwo zarówno <b>sprzęt ortopedyczny</b>, jak i <b>sprzęt rehabilitacyjny</b>, 
    drobny sprzęt medyczny, czy sprzęt niezbędny w powrocie do sprawności i codziennym funkcjonowaniu osób 
    z niepełnosprawnością ruchową, starszych bądź po przebytych urazach i zabiegach operacyjnych, np.: 
    stabilizatory kończyn dolnych, ortezy tułowia, kołnierze, gorsety, pasy przepuklinowe, rajstopy i 
    podkolanówki na żylaki, poduszki ortopedyczne, kule ortopedyczne, laski, balkoniki, chodziki, czy wózki inwalidzkie.</div>}
    image={grafika1}
    alt="Sprzeęt ortopedyczny"
    />
        <SingleBox2
    description={<div>Bardzo dużą uwagę przywiązujemy do <b>jakości naszych produktów</b>, dlatego oferujemy najlepszy, 
      starannie wyselekcjonowany i sprawdzony sprzęt najbardziej renomowanych producentów. <b>Dbamy o bezpieczeństwo</b> 
      odwiedzających nas pacjentów, dlatego wszystkie produkty znajdujące się w ofercie sklepu posiadają wymagane certyfikaty. </div>}
    image={grafika2}
    alt="Wózek inwalidzki"
    />
    <SingleBox
    description={<div>Od początku powstania sklepu naszym głównym celem i misją jest być jak największym <b>wsparciem dla osób starszych, chorych</b> i osób, które nie są
    w stanie funkcjonować samodzielnie. Staramy się pomóc naszym klientom
        w powrocie do maksymalnej sprawności i zapewnieniu sobie lub bliskim godnych i <b>komfortowych warunków życia.</b></div>}
    image={grafika3}
    alt="Osoba z balkonikiem rehabilitacyjnym"

    />
        <SingleBox2
    description={<div>Jednocześnie <b>dbamy o to, by utrzymywać niskie i konkurencyjne ceny</b>. Chcemy sprawiać, by szybka i kompleksowa pomoc w powrocie do zdrowia
    i podnoszeniu jakości życia była dostępna dla każdego. Posiadamy również <b>umowę z Narodowym Funduszem Zdrowia</b> na świadczenie usług z zakresu zaopatrzenia w sprzęt ortopedyczny i środki pomocnicze.
        <b>Na wniosek NFZ</b> wydajemy ortezy, wózki, chodziki, czy akcesoria ortopedyczne. </div>}
    image={grafika4}
    alt="Logo NFZ"

    />
   </section>
  )
}
export default About