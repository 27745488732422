import * as React from 'react'
import Header from "../components/header"

import { strengths, strengthsWrapper, strengthWrapper } from './strengths.module.css'

import personel from "../images/personel_icons.png"
import help from "../images/pomoc_icons.png"
import price from "../images/ceny_icons.png"
import help2 from "../images/help_icons.png"
import patient from "../images/pacjent_icons.png"
import nfz from "../images/nfz_icons.png"



const Strengths = () => {
  return (
    <section className={strengths}>
        <Header title="Nasze atuty"></Header>
        <div className={strengthsWrapper}>
                <div className={strengthWrapper}>
                    <img src={personel} alt={'Wykwalifikowany, pomocny i rzetelny personel'} />
                    <p>Wykwalifikowany, pomocny i rzetelny personel</p>
                </div>
                <div className={strengthWrapper}>
                    <img src={help2} alt={'Pomoc w doborze sprzętu'} />
                    <p>Pomoc w doborze sprzętu</p>
                </div>
                <div className={strengthWrapper}>
                    <img src={price} alt={'Atrakcyjne ceny'} />
                    <p>Atrakcyjne ceny</p>
                </div>
                <div className={strengthWrapper}>
                    <img src={help} alt={'Starannie dobrany, najlepszej jakości asortyment'} />
                    <p>Starannie dobrany, najlepszej jakości asortyment</p>
                </div>
                <div className={strengthWrapper}>
                    <img src={patient} alt={'Naszym priorytetem zawsze jest dobro pacjenta'} />
                    <p>Naszym priorytetem zawsze jest dobro pacjenta</p>
                </div>
                <div className={strengthWrapper}>
                    <img src={nfz} alt={'Posiadamy umowę z Narodowym Funduszem Zdrowia'} />
                    <p>Posiadamy umowę z Narodowym Funduszem Zdrowia</p>
                </div>
        </div>
        </section>
  )
}
export default Strengths