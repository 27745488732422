import * as React from 'react'
import Header from "../components/header"


import { reviewsWrapper, reviewWrapper1, reviewWrapper2, reviewWrapper3, text, name } from './reviews.module.css'

import quote from "../svg/quote-right-solid.svg"


const Reviews = () => {
  return (
    <section>
            <Header title="Recenzje"></Header>

    <div className={reviewsWrapper}>
        <div className={reviewWrapper1}>
           
            <div className={text}>
            <img src={quote} alt="Ikona cudzysłowu"></img>
             <p> Zakupiona w sklepie poduszka ortopedyczna polskiej firmy Reha Fund znacząco poprawiła mój komfort snu. 
               Mogłam liczyć na pomoc w doborze od bardzo pomocnej i fachowej obsługi. Polecam z całego serca.</p>
               </div>
            <p className={name}>
              Agnieszka W.</p>
        </div>
        <div className={reviewWrapper2}>
            <div className={text}>
            <img src={quote} alt="Ikona cudzysłowu"></img>
              <p>Sklep Ortopedyczny czynny jest nawet w soboty, dzięki czemu otrzymałem 
            natychmiastową pomoc w doborze ortezy dla mojej mamy. Dziękuję i serdecznie polecam.</p>
            </div>
            <p className={name}>Kamil S.</p>
        </div>
        <div className={reviewWrapper3}>
            <div className={text}>
            <img src={quote} alt="Ikona cudzysłowu"></img>
              <p>Super zaopatrzenie. Duży wybór akcesoriów ortopedycznych, produkty wysokiej jakości. 
            Z racji mojej choroby często robię zakupy w tym sklepie i jeszcze nigdy się nie zawiodłam!</p>
            </div>
            <p className={name}>Anna G.</p>
        </div>
    </div>
    </section>
  )
}
export default Reviews