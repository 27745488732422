import * as React from 'react'
import { boxWrapper, textWrapper, imgWrapper, description } from './single-box.module.css'



const SingleBox = ({ header, description, image, alt }) => {
    return (
     <div className={boxWrapper}>
         <div className={textWrapper}>
            <h3 >{header}</h3>
            <div className={description}>{description}</div>
        </div>
         <div className={imgWrapper}>
             <img src={image} alt={alt}></img>
        </div>
     </div>
    )
  }
  export default SingleBox