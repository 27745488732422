import * as React from 'react'
import Form from './form'
import InfoBox from './info-box'


import { mainSection, parallax } from './main-section.module.css'
import { container } from './layout.module.css'



const MainSection = () => {
  return (
   <section className={mainSection}>
       <div className={parallax}>
       <div className={container}>
          <Form/>
          <InfoBox/>
       </div>
       </div>
      
   </section>
  )
}
export default MainSection